<template>
  <div>
    <v-autocomplete
      v-model="acModel"
      ref="acField"
      outlined
      dense
      auto-select-first
      :search-input.sync="searchPhrase"
      :maxlength="maxlength"
      :hide-details="!useEditing"
      :items="acItems"
      item-text="l"
      item-value="v"
      :loading="searchInProgress"
      class="ma-1"
      hide-no-data
      :label="txtLabel"
      :placeholder="txtPlaceholder"
      :clearable="!readonly"
      :attach="attachElementSelector"
      return-object
      no-filter
      :disabled="disabled"
      background-color="white"
      @update:search-input="onUpdateSearchInput()"
      :readonly="readonly"
      :hint="hint"
      persistent-hint
    >
    <!--
      @keydown.enter.prevent ="onSelect('enter')"
      -->
      <template v-slot:append-outer>
        <div :id="attachElementId" class="autocomplete-option-table" @click="onSelect('click')">
        </div>
        <v-icon
          v-if="showClearSelectedItemIcon"
          @click="clearSelectedItem()"
          color="red"
        >
          mdi-close-outline
        </v-icon>
      </template>
      <template v-slot:item="{ item }">
        <div>
          <div v-for="labelCol of labelCols" :key="labelCol.name" :style="{ 'flex-basis': labelCol.width + '%' }">
            {{ item[labelCol.name] ? item[labelCol.name] : labelCol.default ? labelCol.default : '' }}
          </div>
        </div>
      </template>
      <template v-slot:prepend v-if="useEditing">
        <v-slide-x-reverse-transition
          mode="out-in"
          
        >
          <v-icon
            :key="`icon-${isEditing}`"
            :color="isEditing ? 'success' : 'info'"
            @click="isEditing = !isEditing"
            v-text="isEditing ? 'mdi-check-circle-outline' : 'mdi-pencil-outline'"
          ></v-icon>
        </v-slide-x-reverse-transition>
      </template>
    </v-autocomplete>
    <!-- <pre>searchPhrase: {{ searchPhrase }}</pre>
    <pre>acModel: {{ acModel }}</pre> -->
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import useCore from '../helpers/core'
// import { useI18n } from 'vue-i18n'

export default {
  name: 'AutoComplete',
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      default: null,
    },
    apiEndPoint: {
      type: String,
      required: true,
    },
    txtLabel: {
      type: String,
      required: true,
    },
    txtPlaceholder: {
      type: String,
      default: 'Ange söksträng',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    doFocus: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 100,
    },
    mode: {
      type: String,
      required: true,
    },
    searchableItems: {
      type: Array,
      required: false,
    },
    searchableItemLabelCols: {
      type: Array,
      required: false,
    },
  },
  setup(props, context) {
    if (props.searchableItems) {
      console.log('auto-complete - got ' + props.searchableItems.length + ' items to search among');
    }
    // use
    const { callApi, debounce } = useCore();

    // static
    const useEditing = props.mode == 'single';
    const attachElementId = 'v-search-option-attach-' + props.label;
    const attachElementSelector = '#' + attachElementId;

    // ref
    const acModel = ref(null);
    const acField = ref(null);
    const acItems = ref([]);
    const labelCols = props.searchableItemLabelCols || ref([]);
    console.log('labelCols', labelCols);
    const requestCounter = ref(0);
    const searchInProgress = ref(false);
    const enterWasPressedRecently = ref(false);
    const recentInput = ref('');
    const searchPhrase = ref('');

    const isEditing = ref(true);

    const doLogApiCall = true;

    // computed
    const hint = computed(() => !useEditing ? '' : !isEditing.value ? 'Klicka på pennan för att ändra' : 'Klicka på bocken för att spara');
    const showClearSelectedItemIcon = computed(() => useEditing && acModel.value);
    const readonly = computed(() => useEditing && !isEditing. value);

    // watch
    watch(() => props.doFocus, newValue => {
      // console.log('ac props.doFocus', newValue);
      if (newValue) {
        setFocus();
      }
    });
    watch(() => enterWasPressedRecently.value, (newValue) => {
      // console.log('enterWasPressedRecently', newValue);
      if (!newValue) {
        setAutocomplete('enterWasPressedRecently');
      }
    });
    watch(() => props.modelValue, newValue => {
      // when parent sends modeValue, we want to preselect the input with it
      // console.log('ac modelValue', newValue);
      // in order for an autocomplete field to display a value, the value must me present among the items, so we do:
      acItems.value = [newValue];
      // we also select the model (a watch on the model will take care of displaying the input in the field also)
      acModel.value = newValue;
    });
    watch(() => isEditing.value, (newValue) => {
      if (newValue) {
        setTimeout(function() {
          setFocus();
        }, 350);
      }
    });
    watch(() => acModel.value, (newValue) => {
      switch (props.mode) {
        case 'single':
          if (newValue) {
            // console.log('setting searchPhrase.value to ', newValue.l);
            searchPhrase.value = newValue.l;
          } else {
            searchPhrase.value = '';
          }
        break;
      }
      if (useEditing) {
        isEditing.value = acModel.value == null;
      }
    });

    const setFocus = () => {
      acField.value.$refs.input.focus();
    }

    const onUpdateSearchInput = () => {
      // Debounce the input and wait for a moment
      debounce(getItems, 200)(searchPhrase.value);
      // keep track of what the input value was recently, in order to track barcode scan
      setTimeout(function() {
        // console.log('setting recentInput to', searchPhrase.value);
        recentInput.value = searchPhrase.value;
      }, 350);
    }

		const onSelect = referer => {
      console.log('onSelect', referer);
      // console.log('acModel', acModel.value);
      switch (referer) {
        case 'enter':
          enterWasPressedRecently.value = true;
          setTimeout(function() {
            enterWasPressedRecently.value = false;
          }, 300);
        break;
      }

      // console.log('recentInput', recentInput.value);
      if (isBarcodeScanInput(referer)) {
        //when a barcode is scanned, the enter is pressed right after the an input from scracth and a search is not performed and no acModel is selected, so we emit event to function that tries to do something directly with the input
        // console.log('do barcode search');
        context.emit('onBarcodeScan', searchPhrase.value);
        setAutocomplete('onEnter ' + referer);
      } else if (acModel.value) {
        context.emit('onSelection', acModel.value);
        setAutocomplete('onSelect ' + referer);
      } else {
        // console.log('onSelect - did nothing', acItems.value);
      }
    }

    const isBarcodeScanInput = referer => {
      console.log('isBarcodeScanInput', referer);
      if (referer != 'enter') {
        // enter was not pressed
        return false;
      }
      const inputWasBlankAShortWhileAgo = recentInput.value == null || recentInput.value.length == 0;
      const inputIsPrettyLongNow = searchPhrase.value.length > 4;
      const somethingWasRapidlyInputted = inputWasBlankAShortWhileAgo && inputIsPrettyLongNow;
      // due to we know this was triggered by an enter, a rapid input suggests a barcode scan
      return somethingWasRapidlyInputted;
    }

    const setAutocomplete = (referer) => {
      console.log('setAutocomplete', referer);
      switch (props.mode) {
        case 'addToList':
          // blank in order to receice next input
          // console.log('blanking input');
          searchPhrase.value = '';
          clearSelectedItem();
        break;
      }
      // isEditing.value = false;
    }

    const clearSelectedItem = () => {
      // it was better to not null this here, in order for the same product to be entered directly again - otherwise it did not trigger, cause that product was still selected in the item menu, and did not trigger a selection, cause it was already selected
      // acModel.value = null;
      acItems.value = [];
      context.emit('onClear');
    }

    const getItems = (phrase) => {
      const doLogReasons = false;
      if (!phrase) {
        return;
      }
      const searchValue = phrase.trim();
      if (enterWasPressedRecently.value) {
        if (doLogReasons) {
          console.log('NO getItems - enter was pressed recently');
        }
        return;
      }
			if (searchValue.length < 2) {
				//input too short
        if (doLogReasons) {
          console.log('NO getItems - input too short');
        }
				acItems.value = [];
				return;
			}
			// Items have already been requested
			if (searchInProgress.value) {
        if (doLogReasons) {
          console.log('NO getItems - search in progress');
        }
				return;
			}
      if (phrase != searchPhrase.value) {
        if (doLogReasons) {
          console.log('NO getItems - old phrase debouncing (' + phrase + ' <> ' + searchPhrase.value + ')');
        }
        return;
      }
			//ok to search
      if (props.searchableItems) {
        // search among local array
        const phraseToMatch = phrase.toUpperCase().replace(/[^A-Z0-9]/g,'');
        const phraseToMatchLength = phraseToMatch.length;
        // console.log('labelCols', labelCols.value);
        // console.log('phrase', phrase, phraseToMatchLength);
        const maxDisplayLimit = 25;
        // console.log('starting filter search');
        acItems.value = props.searchableItems.reduce((items, item) => {
          if (item.c == phraseToMatch) {
            // if perfect match, we prepend
            items.unshift(item);
          } else if (items.length < maxDisplayLimit && item.c.substr(0, phraseToMatchLength) == phraseToMatch) {
            // if partial match and not reached limit - we append
            items.push(item);
          }
          return items;
        }, []);
        // console.log('acItems.value', acItems.value);
      } else {
        // search remotely via api call
        searchInProgress.value = true;
        requestCounter.value++;
        const counter = requestCounter.value;
        callApi({
          method: 'get',
          path: props.apiEndPoint + '/' + counter + '/' + searchValue,
          doLog: doLogApiCall,
        })
          .then(res => {
            searchInProgress.value = false;
            // console.log('res', res);
            const currentRequestCounter = requestCounter.value;
            if (res.requestCounter != currentRequestCounter) {
              // console.log('ignoring response with counter ' + res.requestCounter + ' (current counter ' + currentRequestCounter + ')');
              // ignore request due to old
              return;
            }
            if (res.phrase != searchPhrase.value.trim()) {
              // console.log('ignore result due input has changed from ' + res.phrase + ' to ' + searchPhrase.value.trim());
              return;
            }
            // console.log('ok to use options of counter ' + res.requestCounter);
            labelCols.value = res.labelCols;
            acItems.value = res.items;
          })
          .catch((err) => {
            searchInProgress.value = false;
            return err;
          });
      }
    }

    return {
      searchPhrase,
      acItems,
      labelCols,
      searchInProgress,
      acModel,
      onSelect,
      onUpdateSearchInput,
      acField,
      readonly,
      hint,
      useEditing,
      isEditing,
      attachElementId,
      attachElementSelector,
      showClearSelectedItemIcon,
      clearSelectedItem,
    }
  },
}
</script>

<style scoped>
div.autocomplete-option-table div.v-list-item > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
</style>