export default function() {
  const getBruttoOfNetto = (amount, vatPercent) => {
    return amount * (1 + vatPercent / 100);
  }

  const getNettoOfBrutto = (amount, vatPercent) => {
    return amount / (1 + vatPercent / 100);
  }

  return {
    getBruttoOfNetto,
    getNettoOfBrutto,
  }
}